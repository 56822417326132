<template>
  <label>
    <span class="form-label d-block fw-bold">{{ label }}</span>
    <input
      ref="refAppInput"
      :value="modelValue"
      class="form-control"
      :class="{
        'is-invalid': isInvalid
      }"
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :inputmode="inputmode"
      :disabled="disabled"
      :readonly="readonly"
      :pattern="pattern"
      :autocomplete="autocomplete"
      @input="onInput"
    >
    <div class="d-flex justify-content-between">
<!--      <span
        class="invalid-feedback me-10 d-block"
      >
        Компания с данным ИНН уже зарегистрирована.
        Если это ваша компания, обратитесь в тех. поддержку  для подтверждения прав.
      </span>-->
      <span
        class="invalid-feedback me-10"
        :class="{
          'd-block': isInvalid
        }"
      >
        {{ errorMessage }}
        <slot  name="error-message"/>
      </span>
      <span class="ms-auto">
        {{ note }}
      </span>
    </div>
  </label>
</template>

<script setup lang="ts">
import { MaskInput } from "maska";
import type { MaskTokens, MaskType } from "maska";
import type { PropType } from "vue";

//-----PROPS-----\\
const props = defineProps({
  modelValue: { type: String, default: undefined },
  label: { type: String, default: undefined },
  note: { type: String, default: undefined },
  name: { type: String, default: undefined },
  type: { type: String as PropType<'email'|'number'|'password'|'tel'|'text'|'url'>, default: 'text' },
  placeholder: { type: String, default: undefined },
  inputmode: { type: String, default: undefined },
  disabled: { type: Boolean, default: undefined },
  readonly: { type: Boolean, default: undefined },
  pattern: { type: String, default: undefined },
  maska: { type: Object as PropType<MaskType>, default: undefined },
  maskaTokens: { type: Object as PropType<MaskTokens>, default: undefined },
  autocomplete: { type: String, default: undefined },
  errorMessage: { type: String, default: undefined },
  isInvalid: { type: Boolean, default: undefined },
});
const emits = defineEmits(['update:modelValue']);

//-----VARIABLES-----\\

//-----STATE-----\\
const refAppInput = ref();

//-----COMPUTED-----\\

//-----ASYNC-----\\

//-----METHODS-----\\
function onInput(e) {
  emits('update:modelValue', e.target.value);
}

onMounted(() => {
  if (props.maska !== undefined) {
    new MaskInput(refAppInput.value, {
      mask: props.maska ?? null,
      tokens: props.maskaTokens,
    });
  }
})
</script>